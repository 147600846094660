import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TestView from "@/views/TestView";
import ResultView from "@/views/ResultView";
import ScreenView from "@/views/ScreenView";
import ScreenView2 from "@/views/ScreenView2";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },
  {
    path: '/result',
    name: 'result',
    component: ResultView
  },
  {
    path: '/screen',
    name: 'screen',
    component: ScreenView
  }
  ,
  {
    path: '/screen2',
    name: 'screen2',
    component: ScreenView2
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
