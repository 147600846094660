<template>
  <nav style="position: relative;z-index: 2" v-if="false">
    <router-link to="/">Home</router-link> |
    <router-link to="/test">Test</router-link> |
    <router-link to="/result">Result</router-link> |
    <router-link to="/screen">Screen</router-link>
  </nav>
  <div class="skmx">
    <router-view/>
  </div>

</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
#app {
  font-family: 'Montserrat', sans-serif;
}

.skmx {
  /*width: 1024px;
  height: 768px;*/
  /*position: absolute;*/
  /*left: calc(50% - 512px);
  top: calc(50% - 384px);*/
  background-color: #F4F4F4;

}
body {
  padding: 0;
  margin: 0;
}
</style>
