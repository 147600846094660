<template>
  <div class="result">
    <div class="toplogo">
      <div class="logo">
        <a href="/">
          <img :src="require('@/assets/img/prolet.png')">
        </a>
      </div>
    </div>
    <div class="content">
      <div class="text">
        <h2>{{this.dt.gname}},<br>поздравляем!</h2>
        <div class="desc">
          По результатам теста обрати внимание на <b>{{ rank }}!</b> На электронную почту мы отпр<span @click="plsGoHome()">а</span>вили всю информацию.
        </div>
        <div class="link">
          <div class="image" >
            <img :src="require('@/assets/img/www.png')">
          </div>
          <div class="text1">ЯПРОФ.РФ</div>
        </div>
      </div>
      <div class="imager" v-if="image!=null">
        <img :src="require('@/assets/way/'+image)">
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'HomeView',
  components: {

  },
  mounted() {
    this.calcProf()
    this.chnVideo()
  },
  data() {
    return {
      rank: null,
      image: null,
      wayid: null,
      city: this.dt.currentCity,
    }
  },
  methods: {
    calcProf() {
      var maxparam = 0
      var ot = [];
      for(var i=0;i<8;i++) {
        if (this.dt.result[i]>maxparam) {
          maxparam = this.dt.result[i]
          ot = []
          ot.push(i)
        } else {
          if (this.dt.result[i]==maxparam) {
            ot.push(i)
          }
        }

      }
      var myres=0;
      if (ot.length>1) {
        myres = ot[Math.floor(Math.random()*ot.length)]
        this.wayid = myres+1
      } else {
        myres = ot[0];
        this.wayid = myres+1
      }
      this.sendMail()
      switch (myres) {
        case 0:
          this.rank = "Химическую отрасль";
          this.image = 'l_him.png'
          break;
        case 1:
          this.rank = "Сельскохозяйственную отрасль";
          this.image = 'l_selh.png'
          break;
        case 2:
          this.rank = "Металлургическую отрасль";
          this.image = 'l_metall.png'
          break;
        case 3:
          this.rank = "Машиностроительную отрасль";
          this.image = 'l_mash.png'
          break;
        case 4:
          this.rank = "Лёгкую промышленность";
          this.image = 'l_prom.png'
          break;
        case 5:
          this.rank = "Фармацевтическую отрасль";
          this.image = 'l_farm.png'
          break;
        case 6:
          this.rank = "Железнодорожную отрасль";
          this.image = 'l_jel.png'
          break;
        case 7:
          this.rank = "Атомную отрасль";
          this.image = 'l_atom.png'
          break;
      }
    },
    chnVideo() {
      this.axios.post(this.dt.urlVideo, {message: 0});
    },
    sendMail() {
      console.warn(this.dt.gmail+' '+this.dt.gname+' '+this.wayid)
      if (this.dt.gmail!=null && this.dt.gname!=null && this.wayid!=null ) {
        let _this = this
        //https://npriority.ru/api/sendProf
        this.axios.get('https://prof.digitalists.ru/api/sendProf', {
          params: {
            mail: _this.dt.gmail,
            name: _this.dt.gname,
            way: _this.wayid,
            city: _this.city,
          },

        })
            .then(function (response) {
              if(response.data==1) {
                console.warn('sended')
              }
            })
            .catch(function (error) {
              console.log(error)
            });
      }


    },plsGoHome() {

        this.$router.push({name:'home'})


    },
  }
}
</script>

<style scoped>
.result {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 142px);
  height: 100%;
  padding: 0 73px 0 69px;
}
.toplogo .logo img {
  width: 355px;
  height: auto;
}
.toplogo {
  margin-top: 65px;
}
.content {
  display: flex;
  align-items: center;
}
.content .imager img {
  width: 379px;
  height: auto;
}
.content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #494949;
  margin-bottom: 50px;
}
.content .text {
  padding-top: 100px;
  margin-right: 70px;
}
.content .text .desc {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #494949;
  margin-bottom: 28px;
}
.content .text .link {
  display: flex;
  align-items: center;
}
.content .text .link .image {
  margin-right: 14px;
}
.content .text .link .image img{
  width: 43px;
  height: auto;
}
.content .text .link .text1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  color: #494949;
}
</style>