export default {
    gname:null,
    gmail:null,
    current: 0,
    points: 0,
    result: [0,0,0,0,0,0,0,0],
    currentCity: 2,
    //urlVideo: 'https://npriority.ru/api/videomessage',
    //urlVideo:'https://npriority.ru/api/videomessageekat',
    urlVideo:'https://prof.digitalists.ru/api/videomessageekat',
    //eventVideo: 'my-event',
    eventVideo: 'my-eventekat',
    //chanelVideo: 'chatbox',
    chanelVideo: 'chatboxekat',
    qst: [
        [
            'Кого бы ты выбрал в качестве репетитора в подготовке к вступительным экзаменам?',
            [
                ['А','Д.И. Менделеев','1'],
                ['Б','С.П. Королев','4'],
                ['В','А.Д. Сахаров','8'],
                ['Г','В.А. Юдашкин','5']
            ],
        ],
        [
            'Какой бизнес ты бы начал, если бы имел уверенность в его успехе?',
            [
                ['А','Производство лекарств, спасающих  жизни','6'],
                ['Б','Изготовление крафтового сыра','2'],
                ['В','Услуги кузнеца','3'],
                ['Г','Туризм по России ','7']
            ],
        ],
        [
            'Какой суперсилой ты бы хотел обладать?',
            [
                ['А','Сверхпрочная ткань','5'],
                ['Б','Исцелять людей ','6'],
                ['В','Перемещаться на любые расстояния','7'],
                ['Г','Ускорять рост кукурузы силой мысли','2']
            ],
        ],
        [
            'Какой твой любимый предмет в школе?',
            [
                ['А','Физика','8'],
                ['Б','Химия ','1'],
                ['В','Биология','6'],
                ['Г','География','7']
            ],
        ],
        [
            'Какой предмет тебе нравится больше всего?',
            [
                ['А','<img src="'+require('@/assets/img/iq1.png')+'" style="height: 69px;width: auto" class="fimage">','4'],
                ['Б','<img src="'+require('@/assets/img/iq2.png')+'" style="height: 69px;width: auto" class="fimage">','5'],
                ['В','<img src="'+require('@/assets/img/iq3.png')+'" style="height: 69px;width: auto" class="fimage">','3'],
                ['Г','<img src="'+require('@/assets/img/iq4.png')+'" style="height: 69px;width: auto" class="fimage">','2']
            ],
        ],
        [
            'Что вы видите на картинке?<img src="'+require('@/assets/img/qtw.png')+'" width="150">',
            [
                ['А','Колбу','1'],
                ['Б','Ботинок','5'],
                ['В','Молот','3'],
                ['Г','Двигатель','4']
            ],
        ],
        [
            'Что ты возьмешь на необитаемый остров?',
            [
                ['А','Семена картофеля','2'],
                ['Б','Аптечку','6'],
                ['В','Соляную кислоту','1'],
                ['Г','Топор','3']
            ],
        ],
        [
            'Что обозначает слово "купе"?',
            [
                ['А','Двухместный автомобиль ','4'],
                ['Б','Отсек в поезде','7'],
                ['В','Вид пуговицы','5'],
                ['Г','Часть реактора','8']
            ],
        ],
        [
            'Что ты выберешь в качестве средства передвижения?',
            [
                ['А','Автомобиль','4'],
                ['Б','Ракету','8'],
                ['В','Поезд','7'],
                ['Г','Комбайн','2']
            ],
        ],
        [
            'Что ты считаешь главным изобретением человечества?',
            [
                ['А','Пенициллин ','6'],
                ['Б','Сталь','3'],
                ['В','Одежда','5'],
                ['Г','Таблица Менделеева','1']
            ],
        ],
        [
            'Какое прозвище ты бы выбрал для себя?',
            [
                ['А','Стальная махина','3'],
                ['Б','Поезд справедливости','7'],
                ['В','Урановый барсук','8'],
                ['Г','Грозная лада','4']
            ],
        ],
        [
            'Какую одежду ты бы выбрал?',
            [
                ['А','Соломенную шляпу','2'],
                ['Б','Костюм химзащиты','1'],
                ['В','Белый халат','6'],
                ['Г','Свинцовый плащ','8']
            ],
        ],
        [
            'Какую мировую проблему ты бы хотел решить?',
            [
                ['А','Лекарство от всех болезней','6'],
                ['Б','Неиссякаемый источник энергии','8'],
                ['В','Победа над мировым голодом','2'],
                ['Г','Проблему логистики ','7']
            ],
        ],
        [
            'Какой навык ты бы хотел в себе развить?',
            [
                ['А','Внимательность','5'],
                ['Б','Силу','3'],
                ['В','Креатив','4'],
                ['Г','Эрудицию','1']
            ],
        ],
        [
            'Какое достижение наших ученых ты считаешь наиболее значимым?',
            [
                ['А','Вакцина от COVID-19','6'],
                ['Б','Сорбент для очистки водоёмов и почвы','1'],
                ['В','Сталь, которая не теряет свои свойства','3'],
                ['Г','Лазер высокой мощности','8']
            ],
        ],
        [
            'Без какой профессии жизнь стала бы тяжелее?',
            [
                ['А','Модельер','5'],
                ['Б','Робототехник','4'],
                ['В','Ветеринар','2'],
                ['Г','Инженер качества','7']
            ],
        ],
    ]
}