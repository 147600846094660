<template>
  <div class="screen2">
    <video-background

        :src="mainsrc"

        style="max-height: 100vh; height: 100vh;max-width: 100vw;width: 100vw"

    >

    </video-background>

  </div>
</template>

<script>


export default {
  name: 'ScreenView2',
  components: {

  },
  mounted() {
    //this.countDownTimer ()
    console.log('subscribing to `my-channel`...', {
      $pusher: this.$pusher,
    })

    const channel = this.$pusher.subscribe('chatbox');

    channel.bind('pusher:subscription_succeeded', () => console.log('subscription succeeded'))

    channel.bind('my-event', event => {
      console.log(event.message)
      this.vidtype=event.message;

    })
  },
  unmounted() {
    console.log('unsubscribing...')
    this.$pusher.unsubscribe('chatbox')
  },
  data() {
    return {
      vidtype: 0,
      mainsrc: require('@/assets/video/720/home.mp4')
    }
  },
  methods: {

  },
  watch: {
    vidtype(type) {
      if (type===0) {
        this.mainsrc = require('@/assets/video/720/home.mp4')

      } else {
        this.mainsrc = require('@/assets/video/720/test.mp4')
      }
    }
  }
}
</script>

<style scoped>
.screen2 {
  overflow-x: hidden;
  position: absolute;top: 0;
}


</style>