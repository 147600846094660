<template>
  <div class="home">
    <div class="place">
      <div class="logo">
        <img :src="require('@/assets/img/logo.png')">
      </div>
      <form v-on:submit="sendmail" class="form" novalidate="true">
        <input type="text" placeholder="Алёша" v-model="sname">
        <input type="email" placeholder="professional@mail.ru" v-model="smail">
        <div class="chk">
          <input type="checkbox" id="pers" v-model="pers">
          <label for="pers">
            Согласие на <span class="openlink" @click.stop="persS=true">обработку персональных данных</span>
          </label>
        </div>
        <ul class="errors">
          <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
        </ul>
        <button class="gotst" type="submit">Начать тест</button>
      </form>
    </div>
    <div class="soglobr" v-if="persS">
      <h2>СОГЛАСИЕ
        на обработку <br>персональных данных</h2>
      <ol>
        <li>Субъект персональных данных: лицо, предоставившее свои персональные данные Оператору и подписавшее настоящее Согласие (далее так же – «Участник»).</li>
        <li>Оператор обработки персональных данных: ООО «Про-мастер» (далее – «Оператор»), имеющее место нахождения по адресу: 117218, Россия, Москва, ул. Большая Черемушкинская, д. 32, к. 1, помещ. 1, ком. 20</li>
        <li>Цель обработки персональных данных: организация участия субъекта персональных данных в мероприятиях, в том числе в качестве участника мероприятия; выполнение требований законодательных актов, нормативных документов. Указанные действия могут совершаться Оператором либо третьими лицами, привлеченными Оператором, посредством направления Участнику сообщений на адреса электронной почты (e-mail).</li>
        <li>Перечень персональных данных: ФИО, адрес электронной почты (e-mail).</li>
        <li>Перечень действий с персональными данными (обработка персональных данных): Оператор осуществляет обработку персональных данных – сбор, запись, систематизацию, накопление, использование, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</li>
        <li>Срок действия Согласия: Согласие действует  в течении одного года или до момента его отзыва Участником.</li>
        <li>Отзыв Согласия: Согласие может быть отозвано субъектом персональных данных путем направления письменного заявления в адрес Оператора.</li>
      </ol>
      <p>Настоящим я подтверждаю свое согласие и разрешение на обработку персональных данных на условиях, изложенных в настоящем Согласии.</p>
      <div class="csbtn" @click.stop="persS=false">закрыть</div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'HomeView',
  components: {

  },
  data() {
    return {
      smail: null,
      sname: null,
      errors: [],
      pers: null,
      persS: false,

    }
  },
  mounted() {
    this.chnVideo()
    this.resetAllData()
  },
  methods: {
    chnVideo() {
      this.axios.post(this.dt.urlVideo, {message: 0});
    },
    sendmail: function(event) {
      event.preventDefault();
      this.errors = [];
      if (this.sname=='' || this.sname==null) {
        this.errors.push('Укажите имя');
      }
      if (!this.smail) {
        this.errors.push('Укажите электронную почту');
      } else if (!this.validEmail(this.smail)) {
        this.errors.push('Укажите корректный адрес электронной почты');
      }
      if (this.pers == null || this.pers == false) {
        this.errors.push('Дайте согласие на обработку персональных данных');
      }

      if (!this.errors.length) {
        //все впорядке
        this.dt.gname = this.sname
        this.dt.gmail = this.smail
        this.$router.push({name: 'test'})
      }


    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resetAllData() {
      this.dt.current=0
      this.dt.gname=null
      this.dt.gmail=null
      this.dt.result=[0,0,0,0,0,0,0,0]
    }

  }
}
</script>

<style scoped>
.home {
  background: url('~@/assets/img/homebg.png') center no-repeat;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.place {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
.logo {
  margin-top: 41px;
}
.logo img {
  width: 224px;
  height: auto;
}
.gotst {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  display: inline-block;
  background-color: #2F62F6;
  padding: 19px 34px 23px;
  border-radius: 15px;
  margin: 0 auto;
  border: 0;
  outline: 0;
}
.form {
  margin-top: 33px;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form input {
  width: calc(100% - 54px);
  margin-bottom: 15px;
  border: 1px solid #000000;
  border-radius: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #494949;
  padding: 15px 27px;
  background-color: transparent;
}
.form .chk input {
  width: unset;
  display: none;
}
.form .chk label:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #2F62F6;
  border-radius: 8px;
  left: 0;
  transition: all ease-out .3s;
}
.form .chk input:checked + label:before {
  background-color: #2F62F6;
}
.form .chk {
  position: relative;
  margin-top: 5px;
  padding-left: 30px;
}
.errors {
  list-style: none;
  padding: 0;
  margin: 13px 0 30px;

  min-height: 69px;
}
.errors li {
  padding-left: 11px;
  position: relative;
  margin-bottom: 4px;
}
.errors li:before {
  content: "!";
  position: absolute;
  left: 0;
  color: #FDC300;
  font-size: 12px;
  font-weight: 700;
}
.form .errors {}
.soglobr {
  border: 1px solid #2F62F6;
  border-radius: 15px;
  background-color: rgba(255,255,255,.95);
  position: absolute;
  top: 40px;
  left: 70px;
  right: 70px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
}
.soglobr h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #494949;
}
.soglobr ol, .soglobr p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #494949;
}
.soglobr .csbtn {
  background: #2F62F6;
  border-radius: 15px;
  width: 380px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  padding: 19px 0 23px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 20px;
  color: #FFFFFF;
  display: flex;
  align-self: center;
  justify-content: center;

}
.openlink {
  color: #2F62F6;
  border-bottom: 1px solid #2F62F6;
}
</style>
