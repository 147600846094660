import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import Store from '@/conf/mx'
import axios from "axios";
import VueAxios from 'vue-axios'
import VideoBackground from 'vue-responsive-video-background-player'
import PusherPlugin from '@/conf/pusher'
//import Vue3TouchEvents from "vue3-touch-events";

const apiKey = '0abaa6b9b729ab1c5c4c'

const app = createApp(App)
app.use(router)
app.use(VueAxios,axios)
app.use(PusherPlugin, { apiKey, cluster: 'eu' })
//app.use(Vue3TouchEvents);
app.component('video-background', VideoBackground);
app.config.globalProperties.dt = Store
app.config.globalProperties.gname = null
app.config.globalProperties.gmail = null

app.mount('#app')

