<template>
  <div class="screen">

    <video autoplay loop muted :class="[!vidtype?'showed':'']" preload="auto">
      <source :src="require('@/assets/video/720/home.mp4')" type="video/mp4">
      <source :src="require('@/assets/video/720/home.webm')" type="video/webm">

    </video>
    <video autoplay loop muted :class="[vidtype?'showed':'']"  preload="auto">
      <source :src="require('@/assets/video/720/test.mp4')" type="video/mp4">
      <source :src="require('@/assets/video/720/test.webm')" type="video/webm">


    </video>
  </div>
</template>

<script>


export default {
  name: 'ScreenView',
  components: {

  },
  mounted() {
    console.log('subscribing to `my-channel`...', {
      $pusher: this.$pusher,
    })

    const channel = this.$pusher.subscribe(this.dt.chanelVideo);

    channel.bind('pusher:subscription_succeeded', () => console.log('subscription succeeded'))

    channel.bind(this.dt.eventVideo, event => {
      console.log(event.message)
      this.vidtype=event.message;

    })
  },
  unmounted() {
    console.log('unsubscribing...')
    this.$pusher.unsubscribe(this.dt.chanelVideo)
  },
  data() {
    return {
      vidtype: 0
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.screen {
  overflow-x: hidden;
}
.screen video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin: auto;
  z-index: 1;
  opacity: 0;
  transition: all ease-out .3s;
}
.screen video.showed {
  opacity: 1;
}
</style>