<template>
  <div class="test">
    <div class="uppercontrol">
      <div class="qcount">
        <div class="current" >{{this.current+1}}</div>
        <div class="all">16</div>
      </div>
      <div class="timer">
        <div class="clock" @click="plsGoHome()">
          0:{{ cTime }}
        </div>
      </div>
    </div>
    <div :class="['quest',(current==5)?'plsup':'']" v-html="this.dt.qst[current][0]">

    </div>
    <div class="options" >
      <div :class="['vari',(current==4)?'noborder':'']" v-for="opt in this.dt.qst[current][1]" :key="opt[0]" @click.stop.prevent="checkRight($event,opt[2])"><span :class="'cat'+opt[2]">{{opt[0]}}</span><div class="npvar" v-html="opt[1]"></div></div>

    </div>
    <div class="bottomparty">
      <div class="footerlogo">
        <img :src="require('@/assets/img/prolet.png')">
      </div>
      <div class="btpl">
        <div :class="['buttonnext',answered?'active':'']" @click="changeCurrentButton()">
          далее
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'HomeView',
  components: {

  },
  data() {
    return {
      current: this.dt.current,
      answered: false,
      way: 0,
      currentTime: 20,
      cTime:'00',
      timer: null,
      gohometimer: null,
    }
  },
  mounted() {
    this.startTimer()
  this.chnVideo()
  },
  unmounted() {
    this.stopTimer()
  },
  methods: {
    addAnswer() {
      if (this.answered) {

        this.dt.result[this.way]+=1
        this.answered=0

      }
      this.dt.current++
      this.current = this.dt.current
      console.warn(this.dt.result)
      if (document.getElementsByClassName('clicked').length>0) {
        var oppts = document.getElementsByClassName("clicked")[0];
        oppts.classList.remove('clicked')
      }
      this.currentTime=20;
      this.startTimer();
      console.log(this.dt.current+'/'+this.dt.qst.length)

    },
    changeCurrent() {
      if (this.dt.current<this.dt.qst.length-1) {
        this.stopTimer();
        this.addAnswer()

      } else  {
        console.log('qwe')
        this.$router.push({name: 'result'})
      }
    },
    changeCurrentButton() {
      if (this.dt.current<this.dt.qst.length-1) {
        if (this.answered) {
          this.stopTimer();
          this.addAnswer()
        }

      } else  {
        console.log('qwe')
        this.$router.push({name: 'result'})
      }
    },
    checkRight: function (event,pointCol) {
      if (!this.answered) {
        this.answered=true;
        setTimeout(function () {
          if (event.target.classList.value=='npvar') {
            event.target.parentNode.classList.value = event.target.parentNode.classList.value + ' clicked'
          } else {
            if (event.target.classList.value=='fimage') {
              event.target.parentNode.parentNode.classList.value = event.target.parentNode.parentNode.classList.value + ' clicked'
            }else {
              event.target.classList.value = event.target.classList.value + ' clicked';
            }

          }

          },10)
        this.way = Number(pointCol)-1
      } else {

        var oppts = document.getElementsByClassName("clicked")[0];
        oppts.classList.remove('clicked')
        setTimeout(function () {
          if (event.target.classList.value=='npvar') {
            event.target.parentNode.classList.value = event.target.parentNode.classList.value + ' clicked'
          } else {
            if (event.target.classList.value=='fimage') {
              event.target.parentNode.parentNode.classList.value = event.target.parentNode.parentNode.classList.value + ' clicked'
            }else {
              event.target.classList.value = event.target.classList.value + ' clicked';
            }

          }

        },10)
        this.way = Number(pointCol)-1
      }


    },
    chnVideo() {

        this.axios.post(this.dt.urlVideo, {message: 1});


    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    plsGoHome() {
      if (this.currentTime==17) {
        this.$router.push({name:'home'})
      }

    },


  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer()
        this.changeCurrent()
      }
      if (this.currentTime>9) {
        this.cTime=this.currentTime
      } else {
        this.cTime='0'+this.currentTime
      }

    }
  },
}
</script>

<style scoped>
.test {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 139px);
  height: calc(100% - 132px);
  padding: 42px 72px 70px 67px;
}
.uppercontrol {
  display: flex;
  width: 100%;
}
.uppercontrol .qcount {
  display: flex;
  flex-grow: 1;
}
.qcount .current,.qcount .all {
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #494949;
}
.qcount .current {
  padding-bottom: 36px;
  position: relative;
  min-width: 43px;
  text-align: right;
}
.qcount .current:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 1px;
  background-color: #494949;
  transform: rotate(-45deg);
  top: 40px;
  right: -23px;
}
.qcount .all {
  padding-top: 36px;
}
.timer .clock {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #2F62F6;
  border: 2px solid #2F62F6;
  border-radius: 15px;
  padding: 23px 0px 23px;
  width: 175px;
  text-align: center;
}
.quest {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #494949;
  margin-top: 35px;
  min-height: 58px;
  display: flex;
  align-items: center;
}
.quest.plsup {
  margin-top: 0px;
}
.options {
  margin-top: 50px;
}
.quest.plsup + .options{
  margin-top: 30px;
}
.vari {
  width: 407px;
  background-color: #fff;
  border: 0.5px solid #A6A6A6;
  border-radius: 15px;
  display: inline-flex;
  margin-bottom: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #494949;
  align-items: center;
  margin-right: 67px;
}
.vari.noborder {
  border-color: transparent;
}
.vari:nth-of-type(2n) {
  margin-right: 0px;
}
.vari span {
  display: flex;
  width: 74px;
  min-width: 74px;
  height: 74px;
  align-items: center;
  justify-content: center;
  background-color: #8D8D8D;
  border-radius: 15px;
  margin-right: 13px;
  color: #fff;
  font-weight: 700;
  font-size: 36px;
  line-height: 1;
  letter-spacing: normal;
}
.bottomparty {
  display: flex;
  align-items: center;
  margin-top: 70px;
}
.footerlogo {
  flex-grow: 1;
}
.footerlogo img{
  width: 356px;
  height: auto;
}
.btpl .buttonnext {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 21px 0px 21px;
  background-color: #8D8D8D;

  border-radius: 15px;
  width: 258px;
  text-align: center;
}
.btpl .buttonnext.active {
  background: #2F62F6;
}
.vari.clicked span.cat1,.vari span.clicked.cat1 {
  background-color: #293896;
}
.vari.clicked span.cat2,.vari span.clicked.cat2 {
  background-color: #3DBB95;
}
.vari.clicked span.cat3,.vari span.clicked.cat3 {
  background-color: #F47920;
}
.vari.clicked span.cat4,.vari span.clicked.cat4 {
  background-color: #436990;
}
.vari.clicked span.cat5,.vari span.clicked.cat5 {
  background-color: #FFC20E;
}
.vari.clicked span.cat6,.vari span.clicked.cat6 {
  background-color: #A1489B;
}
.vari.clicked span.cat7,.vari span.clicked.cat7 {
  background-color: #EF4423;
}
.vari.clicked span.cat8,.vari span.clicked.cat8 {
  background-color: #00B9F2;
}
</style>
